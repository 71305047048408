export interface AppConfig {
  BACKEND_API: string;
  FRESH_FRONTEND_URL: string;
  FRESH_CUSTOM_STATUS: {[key: string]: string};
  APIM_API: string;
  APIM_SUBSCRIPTION_KEY: string;
  BASE_URL: string;
  AUTHORITY: string;
  BACKEND_SCOPE: string;
  EXTRA_SCOPES: string[];
  CLIENT_ID: string;
  REDIRECT_URL: string;
  HOTJAR_ID: string;
  HOTJAR_SV: string;
}

async function fetchConfiguration(): Promise<AppConfig> {
  const configElem = document.getElementById('app-config');
  const backendUrl = configElem?.dataset?.backendurl;

  if (!backendUrl) {
    // Should never happen
    throw Error(`Missing backend url setting`);
  }

  const be_response = await fetch(`${backendUrl}config/`, {
    headers: {
      accept: 'application/json; version=1.0',
    },
  });
  if (!be_response.ok) {
    throw Error(`Error fetching backend config: ${await be_response.text()}`);
  }
  const be_config = await be_response.json();

  return {
    BACKEND_API: backendUrl,
    FRESH_FRONTEND_URL: be_config['FRESH_FRONTEND_URL'],
    FRESH_CUSTOM_STATUS: be_config['FRESH_CUSTOM_STATUS'] ?? {},
    BASE_URL: import.meta.env.BASE_URL,
    APIM_API: be_config['APIM_API'],
    APIM_SUBSCRIPTION_KEY: be_config['APIM_SUBSCRIPTION_KEY'],
    AUTHORITY: be_config['AUTHORITY'],
    BACKEND_SCOPE: be_config['BACKEND_SCOPE'],
    EXTRA_SCOPES: be_config['EXTRA_SCOPES'],
    CLIENT_ID: be_config['CLIENT_ID'],
    REDIRECT_URL: be_config['REDIRECT_URL'],
    HOTJAR_ID: be_config['HOTJAR_ID'],
    HOTJAR_SV: be_config['HOTJAR_SV'],
  };
}

const testConfig: AppConfig = {
  BACKEND_API: 'https://localhost/BACKEND_API/',
  FRESH_FRONTEND_URL: 'FRESH_FRONTEND_URL',
  FRESH_CUSTOM_STATUS: {
    '6': 'Awaiting Approval',
    '7': 'Awaiting your reply',
    '8': 'Waiting on a reply for an external provider',
    '10': 'Scheduled',
    '13': 'Ticket is waiting on a Change Request',
  },
  BASE_URL: import.meta.env.BASE_URL,
  APIM_API: 'https://localhost/APIM_API/TODO',
  APIM_SUBSCRIPTION_KEY: 'APIM_SUBSCRIPTION_KEY',
  AUTHORITY: 'https://localhost/AUTHORITY',
  BACKEND_SCOPE: 'https://localhost/BACKEND_SCOPE',
  EXTRA_SCOPES: [],
  CLIENT_ID: 'CLIENT_ID',
  REDIRECT_URL: 'https://localhost',
  HOTJAR_ID: 'HOTJAR_ID',
  HOTJAR_SV: 'HOTJAR_SV',
};

const config: AppConfig =
  import.meta.env.VITEST || import.meta.env.STORYBOOK ? testConfig : await fetchConfiguration();

export default config;
