<template>
  <div>
    <div class="page-title"></div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12 px-0">
          <div class="card docs-container">
            <div id="md-card" class="docs-container">
              <div style="float: right">
                <IconButton
                  :id="`download-${fileConfig.fileName}`"
                  variant="primary"
                  icon-name="printer"
                  @click="generateReport"
                />
              </div>
              <MarkdownViewer
                id="md-body"
                ref="html2Pdf"
                class="md-body"
                :html="true"
                :linkify="true"
                :source="content"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import util from '@/components/navigationOld/util';
import axios from 'axios';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import html2canvas from 'html2canvas';
import '@/assets/fonts/Rubik/Rubik-Regular-normal.js';
import '@/assets/fonts/Rubik/Rubik-Bold-normal.js';
import '@/assets/fonts/Rubik/Rubik-Light-normal.js';

export default {
  name: 'MarkdownFile',
  props: {
    fileConfig: {
      type: Object,
      required: true,
    },
    accessToken: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      content: '',
      mdOptions: {
        markdownIt: {
          linkify: false,
          html: true,
        },
        linkAttributes: {
          attrs: {
            target: '',
            rel: 'noopener',
          },
        },
      },
    };
  },
  computed: {
    config() {
      return this.fileConfig;
    },
  },
  watch: {
    config: {
      handler: async function () {
        await this.getFile();
      },
      deep: true,
    },
  },
  mounted() {
    window.addEventListener('click', this.handleClick);
    this.getFile();
  },
  unmounted() {
    window.removeEventListener('click', this.handleClick);
  },
  methods: {
    handleClick(event) {
      // ensure we use the link, in case the click has been received by a subelement
      let {target} = event;
      while (target && target.tagName !== 'A') target = target.parentNode;
      // handle only links that do not reference external resources
      if (target && target.matches(`a:not([href*='://'])`) && target.href) {
        // some sanity checks taken from vue-router:
        // https://github.com/vuejs/vue-router/blob/dev/src/components/link.js#L106
        const {altKey, ctrlKey, metaKey, shiftKey, button, defaultPrevented} = event;
        // don't handle with control keys
        if (metaKey || altKey || ctrlKey || shiftKey) return;
        // don't handle when preventDefault called
        if (defaultPrevented) return;
        // don't handle right clicks
        if (button !== undefined && button !== 0) return;
        // don't handle if `target="_blank"`
        if (target && target.getAttribute) {
          const linkTarget = target.getAttribute('target');
          if (/\b_blank\b/i.test(linkTarget)) return;
        }
        // don't handle same page links/anchors
        const url = new URL(target.href);
        let to = url.pathname.replace('.md', '');
        // TODO: This needs work!
        to = util.validRouteTitle(to);
        to = to.replaceAll(' ', '_');
        to = to.replaceAll('%20', '_');
        if (window.location.pathname !== to && event.preventDefault) {
          event.preventDefault();
          this.$router.push(to);
        }
      }
    },
    getFile() {
      let url = this.fileConfig.fullPath + '?' + this.accessToken;
      axios.get(url).then((response) => {
        this.content = this.formatContent(response.data);
      });
    },
    formatContent(content) {
      return content.replace(
        /(!\[)(.+)(\]\()(.+)(.jpg|.png|.svg)(\))/g,
        (...match) =>
          `![${match[2]}](${this.fileConfig.storageAccount}${this.fileConfig.container}${this.fileConfig.language}${this.fileConfig.folder}/${match[4]}${match[5]}?${this.accessToken})`,
      );
    },
    async generateReport() {
      const {$el: element} = this.$refs.html2Pdf;
      const doc = new jsPDF('p', 'pt', 'a4');

      const options = {
        margin: 30,
        startY: 30,
        scale: 0.5,
        autoPaginate: true,
      };
      doc.setFont('Rubik-Light', 'normal');

      const canvasHeight = doc.internal.pageSize.getHeight() - 2 * options.margin;

      doc.scale(options.scale, options.scale);

      let currentY = options.startY;

      //add different types of text to page based on the rules below
      const addTextToPage = async (text) => {
        const textStyles = window.getComputedStyle(text.parentNode);
        const fontSize = parseFloat(textStyles.fontSize) - 2;
        const textDecoration = textStyles.textDecoration;

        if (text.isAlert) {
          doc.setFont('Rubik-Regular', 'normal');
        } else {
          doc.setFont('Rubik-Light', 'normal');
        }

        doc.setFontSize(fontSize);
        doc.setTextColor(0, 0, 0);

        const textHeight = doc.getTextDimensions(text.textContent).h;

        if (currentY + textHeight > canvasHeight) {
          doc.addPage();
          currentY = options.startY;
        }

        //if parent is li or ul and parent of parent does not contain class 'alert'
        if (
          text.parentNode.tagName.toLowerCase() === 'ol' ||
          (text.parentNode.tagName.toLowerCase() === 'ul' &&
            !text.parentNode.parentNode.classList.contains('alert'))
        ) {
          doc.setFontSize(fontSize - 3);
          const bullet = '\u2022';
          const listItemText = bullet + ' ' + text.textContent.trim();
          const splitListItemText = doc.splitTextToSize(
            listItemText,
            doc.internal.pageSize.getWidth() - 2 * options.margin,
          );
          splitListItemText.forEach((txt) => {
            doc.text(txt, options.margin, currentY, {
              textDecoration: textDecoration === 'underline' ? 'underline' : 'none',
            });
            if (
              splitListItemText.length > 1 &&
              txt !== splitListItemText[splitListItemText.length - 1]
            ) {
              currentY += textHeight;
            }
          });
        } else if (text.nodeName.toLowerCase() === 'p') {
          doc.setFontSize(fontSize - 2);

          const splitTextContent = doc.splitTextToSize(
            text.textContent,
            doc.internal.pageSize.getWidth() - 2 * options.margin,
          );

          // Draw box around text area if isAlert is true
          if (text.isAlert) {
            // Alert specific list items
            const childList = text.parentNode.querySelector('ul');

            if (childList) {
              const childListItems = childList.querySelectorAll('li');
              if (childListItems.length > 0) {
                childListItems.forEach((item) => {
                  splitTextContent.push(''); //empty string to add a line break
                  item.textContent = '\u2022' + ' ' + item.textContent.trim(); //add bullet point to each item
                  const splitItemText = doc.splitTextToSize(
                    //splitText on item.textContent
                    item.textContent,
                    doc.internal.pageSize.getWidth() - 2 * options.margin,
                  );
                  //add each item to splitTextContent
                  splitItemText.forEach((txt) => {
                    splitTextContent.push(txt);
                  });
                });
              }
            }

            //box drawing options
            const boxMargin = options.margin - 5;
            const lineHeight = parseFloat(textHeight); // Calculate line height based on CSS line-height property
            const numRows = splitTextContent.length; // Number of rows in the text area
            const boxWidth = doc.internal.pageSize.getWidth() - 2 * boxMargin; // Box width equal to page width

            let totalTextHeight = numRows * lineHeight; // Calculate the total height of the text content

            // Adjust the box height if it exceeds the total text height
            const boxHeight = Math.max(totalTextHeight + boxMargin / 2, lineHeight);

            const x = boxMargin; // X-coordinate of the box
            const y = currentY - boxMargin / 2; // Y-coordinate of the box (adjusted to fit within margins)

            const alertColors = {
              info: [79, 147, 222],
              warning: [255, 193, 7],
              danger: [220, 53, 69],
              success: [209, 231, 221],
            };

            const alertType = text.alertType.replace('alert-', '');

            doc.setDrawColor(
              alertColors[alertType][0],
              alertColors[alertType][1],
              alertColors[alertType][2],
            );
            doc.setLineWidth(2); // Set box border width

            // doc.rect(x, y, boxWidth, boxHeight, 'S'); // Draw a box around the text area

            //if first element in parent div
            if (text.parentNode.children[0] === text) {
              doc.line(x, y, x + boxWidth, y); // Top horizontal line
            }

            doc.line(x, y, x, y + boxHeight); // Left vertical line
            doc.line(x + boxWidth, y, x + boxWidth, y + boxHeight); // Right vertical line
            //after the last item in parent div draw bottom line or if last element is a list item draw line under that
            if (
              text.parentNode.children[text.parentNode.children.length - 1] === text ||
              text.parentNode.children[
                text.parentNode.children.length - 1
              ].tagName.toLowerCase() === 'ul'
            ) {
              doc.line(x, y + boxHeight, x + boxWidth, y + boxHeight); // Bottom horizontal line
            }
          }

          splitTextContent.forEach((txt) => {
            //paragraph text
            doc.text(txt.trim(), options.margin, currentY, {
              textDecoration: textDecoration === 'underline' ? 'underline' : 'none',
            });
            if (splitTextContent.length > 1 && txt !== splitTextContent[-1]) {
              currentY += textHeight;
            }
          });
          currentY += textHeight + 1;
        } else {
          currentY += options.margin / 2;
          const splitTextContent = doc.splitTextToSize(
            text.textContent,
            doc.internal.pageSize.getWidth() - 2 * options.margin,
          );
          splitTextContent.forEach((txt) => {
            //headings/title text
            doc.text(txt, options.margin, currentY, {
              textDecoration: textDecoration === 'underline' ? 'underline' : 'none',
            });
            if (splitTextContent.length > 1 && txt !== splitTextContent[-1]) {
              currentY += textHeight;
            }
          });
        }

        currentY += textHeight;
      };

      //main loop function, goes through structure of nodes recursively
      //and remembers lists that have already been processed so we dont get duplicates
      const traverseAndAddContent = async (node, processedLists = new Set()) => {
        if (node.nodeType === Node.TEXT_NODE) {
          const text = node.textContent.trim();
          if (text !== '') {
            addTextToPage(node);
          }
        } else if (node.nodeType === Node.ELEMENT_NODE) {
          //table node logic
          if (node.classList.contains('scroll-table')) {
            const tables = node.getElementsByTagName('table');
            for (let j = 0; j < tables.length; j++) {
              const table = tables[j];
              //use html2canvas to render table as image and add to doc
              const tableImage = await html2canvas(table, {
                scale: 1.25,
                useCORS: true,
              });

              const tableImageWidth = tableImage.width / 2;
              const tableImageHeight = tableImage.height / 2;

              if (currentY + tableImageHeight > canvasHeight) {
                doc.addPage();
                currentY = options.startY;
              }

              doc.addImage(
                tableImage,
                'PNG',
                options.margin,
                currentY,
                tableImageWidth,
                tableImageHeight,
              );

              currentY += tableImageHeight + 30;
            }
          } else if (node.tagName.toLowerCase() === 'ul' || node.tagName.toLowerCase() === 'ol') {
            // Handle lists
            if (!processedLists.has(node)) {
              processedLists.add(node);
              const listItems = node.getElementsByTagName('li');
              for (let i = 0; i < listItems.length; i++) {
                const listItem = listItems[i];
                const bullet = '\u2022';
                const listItemText = bullet + ' ' + listItem.textContent.trim();

                const listItemHeight = doc.getTextDimensions(listItemText).h;

                if (currentY + listItemHeight > canvasHeight) {
                  doc.addPage();
                  currentY = options.startY;
                }

                addTextToPage(listItem);
                currentY += listItemHeight;
              }
              currentY += 10;
            }
          } else if (node.tagName.toLowerCase() === 'p') {
            // Handle images
            const images = node.getElementsByTagName('img');
            if (images.length > 0) {
              //if image[0] has a valid src
              try {
                //use html2canvas to render image as image and add to doc
                const image = await html2canvas(images[0], {
                  scale: 1.25,
                  useCORS: true,
                });

                const imageWidth = image.width / 2;
                const imageHeight = image.height / 2;

                if (currentY + imageHeight > canvasHeight) {
                  doc.addPage();
                  currentY = options.startY;
                }

                doc.addImage(image, 'PNG', options.margin, currentY, imageWidth, imageHeight);

                currentY += imageHeight + 30;
              } catch (e) {
                console.log(e);
              }
            }

            const text = node.textContent.trim();
            if (text !== '') {
              //check for if parent node has alert class
              if (node.parentNode.classList.contains('alert')) {
                node.isAlert = true;
                const alertType = node.parentNode.classList[1];
                node.alertType = alertType;
              }
              addTextToPage(node);
            }
          } else {
            const childNodes = node.childNodes;
            for (let i = 0; i < childNodes.length; i++) {
              await traverseAndAddContent(childNodes[i], processedLists);
            }
          }
        }
      };

      //start traversing the DOM
      await traverseAndAddContent(element);

      //spit out file
      doc.save(this.fileConfig.filePath + '.pdf');
    },
  },
};
</script>

<style lang="scss" scoped>
.docs-container {
  max-width: 64.375rem;
}
@media (max-width: 576px) {
  .download-btn {
    display: none;
  }

  .docs-container {
    padding: 0rem 0.5rem;
  }
}
</style>
