<template>
  <div ref="subMenuTitle">
    <a
      class="sidebar-link sidebar-title my-1 w-100 d-flex justify-content-between align-items-top"
      :class="
        (!item.collapsed && item.children.length > 1) || props.active ? 'sidebar-link-active' : ''
      "
      @click="handleClick"
    >
      <span class="me-2 pe-2 fs-6">{{ item.page.title }}</span>
      <div v-if="item.children.length > 0">
        <VueFeather :type="!item.collapsed ? 'chevron-down' : 'chevron-right'" size="14" />
      </div>
    </a>
    <slot></slot>
  </div>
</template>

<script lang="ts" setup>
import {TreeNodePage} from '@/stores/apolloPlatform/cores/portalNavigation';
import {scrollIntoView} from '@/sphere/scroll';

const route = useRoute();
const subMenuTitle = ref<HTMLDivElement>();

interface Props {
  item: TreeNodePage;
  active?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  active: false,
});

const emits = defineEmits(['collapse']);

const handleClick = () => {
  if (props.item.page.class_name === 'Category') {
    emits('collapse', props.item);
  }
};

watch(
  () => route.meta.activePageNode && route.fullPath,
  (activePage) =>
    activePage &&
    route.meta.activePageNode?.page.id === props.item.page.id &&
    scrollIntoView(subMenuTitle.value as HTMLElement),
  {immediate: true},
);
</script>

<style lang="scss" scoped>
.sidebar-links > li a {
  padding: 0 0;
}

.sidebar-title {
  background-color: #f0f5ff;
  border-radius: 5px;
}

.sidebar-title.active {
  background-color: #f0f5ff;
}

[data-bs-theme='dark'] .sidebar-title.active {
  background-color: var(--bs-secondary-bg);
}

[data-bs-theme='light'] .sidebar-title:hover {
  background-color: #f0f5ff;
}

.sidebar-link {
  border-radius: 5px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  display: block;
  margin-top: 10px;
  margin-bottom: 10px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  position: relative;
  margin-bottom: 10px;
  cursor: pointer;
  background-color: #f0f5ff;
  padding: 4px 8px 4px 28px;
}

[data-bs-theme='dark'] .sidebar-link {
  background-color: var(--bs-tertiary-bg);
  color: white;
}

.sidebar-link:hover {
  background-color: var(--bs-primary-bg-subtle) !important;
}

[data-bs-theme='dark'] .sidebar-link:hover {
  background-color: var(--bs-secondary-bg) !important;
}

.sidebar-link-active {
  background-color: var(--bs-primary-bg-subtle) !important;
}

[data-bs-theme='dark'] .sidebar-link-active {
  background-color: var(--bs-secondary-bg) !important;
}
</style>
