<template>
  <header class="header position-sticky top-0 d-flex flex-shrink-0 px-3">
    <slot name="sidebar-toggle" />
    <nav class="h-100 w-100" aria-label="Top">
      <div class="d-none d-lg-flex h-100 w-100 align-items-center justify-content-between">
        <div class="d-flex align-items-center">
          <div class="ml-2 none block-lg">
            <slot name="left" />
          </div>
        </div>
        <div class="ml-2 h-100">
          <slot name="right" />
        </div>
      </div>
      <!-- Mobile -->
      <div class="d-flex d-lg-none align-items-center w-100 h-100">
        <slot name="mobile" />
      </div>
    </nav>
  </header>
</template>
<style lang="scss" scoped>
.header {
  height: 4rem;
  z-index: 9;
}
</style>
