<template>
  <Teleport to="body">
    <Transition
      name="fade-container"
      appear
      @after-enter="showPanel = true"
      @before-leave="showPanel = false"
    >
      <div v-show="open" style="position: relative; z-index: 10">
        <section class="dialog-container" @click="dismiss">
          <div class="dialog" data-testid="site.dialog">
            <Transition name="fade-panel" @after-leave="$emit('close')">
              <form
                v-if="showPanel"
                ref="form"
                data-testid="site.dialog.form"
                novalidate
                class="dialog-panel needs-validation"
                @submit="submit"
                @click.stop
              >
                <h4 class="flex-shrink-0" data-testid="site.dialog.title">
                  <slot name="header"></slot>
                </h4>
                <article class="dialog-content">
                  <slot></slot>
                </article>
                <div class="d-flex justify-content-end mt-4">
                  <slot name="buttons"></slot>
                </div>
              </form>
            </Transition>
          </div>
        </section>
      </div>
    </Transition>
  </Teleport>
</template>
<script setup>
const emits = defineEmits(['close', 'submit']);

const props = defineProps({
  open: {
    type: Boolean,
    required: true,
  },
  dismissible: {
    type: Boolean,
    default: false,
  },
});

// Used to trigger the panel transition
const showPanel = ref(false);
const dismiss = () => {
  if (props.dismissible) showPanel.value = false;
};
const form = ref(null);

// https://getbootstrap.com/docs/4.6/components/forms/?#how-it-works
const submit = (event) => {
  if (form.value.checkValidity() === false) {
    event.preventDefault();
    event.stopPropagation();
    form.value.classList.add('was-validated');
  } else {
    event.preventDefault();
    emits('submit', form);
  }
  form.value.classList.add('was-validated');
};
</script>
<style lang="scss" scoped>
.fade-container-enter-active,
.fade-container-leave-active {
  transition: opacity 0.3s ease;
}

.fade-container-enter-from,
.fade-container-leave-to {
  opacity: 0;
}

.dialog-container {
  position: fixed;
  inset: 0;
  overflow-y: auto;
  background-color: rgb(0, 0, 0, 0.25);
}

.dialog {
  display: flex;
  min-height: 100%;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  text-align: center;
  height: 100vh;
}

.fade-panel-enter-active,
.fade-panel-leave-active {
  transition: all 0.5s ease;
}

.fade-panel-enter-from,
.fade-panel-leave-to {
  transform: scale(0.95);
  opacity: 0;
}

.fade-panel-enter-to,
.fade-panel-leave-from {
  transform: scale(1);
  opacity: 100;
}

.dialog-panel {
  background-color: var(--bs-body-bg);
  width: 100%;
  max-width: 32rem;
  height: auto;
  max-height: 100%;
  overflow-y: hidden;
  overflow-x: visible;
  border-radius: 1rem;
  padding: 1.5rem;
  text-align: left;
  display: flex;
  flex-direction: column;
}

.dialog-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  overflow-x: visible;
  /*
   * Bootstrap inputs have box-shadow focus. On overflow, focus is cut off
   * Negative margin & padding used to retain element position & show focus.
   */
  margin: -0.25rem;
  padding: 0.25rem;
}
</style>
